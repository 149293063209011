<template>
  <div ref="blockRef">
    <h2
      v-if="block.title"
      v-html="formatTitle(block.title)"
      :class="`-ml-[1.5%] leading-[0.9] whitespace-nowrap ${block.title_small ? 'h1--sm' : 'h1'} ${block.title_color.value}`"
      data-gsap-target="title"
    />

    <Swiper
      v-if="items.length && asSlider"
      class="mt-12 !px-4 sm:mt-24 xl:!px-12"
      :space-between="24"
      :slides-per-view="1.1"
      :slides-per-group="1"
    >
      <SwiperSlide v-for="item in items" :key="item.id" class="!w-[80vw] sm:!w-[32rem]">
        <MediaItem
          :url="item.url"
          :image="item.image.sm"
          :image-srcset="`
            ${item.image.lg.url} ${item.image.lg.width}w,
            ${item.image.md.url} ${item.image.md.width}w,
            ${item.image.sm.url} ${item.image.sm.width}w
          `"
          image-sizes="
            (min-width: 640px): 512px,
            80vw
          "
          :title="item.title"
          :tags="item.project_tags"
          :logo="item.award_logo"
          :date="item.course_dates ? filterPastCourseDates(item.course_dates)[0]?.course_dates_date : null"
          :ratio="type === 'articles' || type === 'courses' ? 'aspect-[5/3]' : undefined"
          :title-below="true"
          data-gsap-target="mediaItem"
        />
      </SwiperSlide>
    </Swiper>

    <div
      v-if="items.length && !asSlider"
      class="grid grid-cols-1 gap-6 mt-12 mx-auto px-4 w-full max-w-8xl sm:grid-cols-2 md:gap-12 md:mt-24"
    >
      <MediaItem
        v-for="item in items"
        :key="item.id"
        :url="item.url"
        :image="item.image.md"
        :image-srcset="`
          ${item.image.lg.url} ${item.image.lg.width}w,
          ${item.image.md.url} ${item.image.md.width}w,
          ${item.image.sm.url} ${item.image.sm.width}w
        `"
        sizes="
          (min-width: 1344px) 632px,
          (min-width: 640px) 45vw,
          95vw
        "
        :title="item.title"
        ratio="aspect-[5/3]"
        :title-below="true"
        data-gsap-target="mediaItem"
      />
    </div>

    <div
      v-if="type === 'reviews' && block.reviews?.length"
      ref="reviewsRef"
      class="pt-12 md:pt-24"
    >
      <div class="flex flex-col gap-8 mx-auto px-4 w-full max-w-6xl">
        <Review
          v-for="review in block.reviews"
          :key="review.id"
          :review="review"
          class="origin-top"
          data-gsap-target="review"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { TitleEntries } from '~/statamic/types/block';

const props = defineProps<{
  block: TitleEntries;
}>();

let ctx: gsap.Context | null;
const type = props.block.type_of_content?.value;
const blockRef = ref();
const reviewsRef = ref();

const items = computed(() => {
  if (type === 'pages' && props.block.pages) {
    return props.block.pages;
  }

  if (type === 'projects' && props.block.projects) {
    return props.block.projects;
  }

  if (type === 'courses' && props.block.courses) {
    return props.block.courses;
  }

  if (type === 'services' && props.block.services) {
    return props.block.services;
  }

  if (type === 'articles' && props.block.articles) {
    return props.block.articles;
  }

  return [];
});

const asSlider = computed(() => {
  return ['articles', 'projects', 'courses'].includes(type);
});

onMounted(() => {
  ctx = useGsap.context((self) => {
    if (typeof self.selector !== 'function') return;

    const titleLeft = self.selector('[data-gsap-target="title"]')[0];
    const titleRight = self.selector('[data-gsap-target="title"] > .text-right')[0];
    const mediaItems = self.selector('[data-gsap-target="mediaItem"]');
    const reviews = self.selector('[data-gsap-target="review"]');

    const timeline = useGsap.timeline({
      scrollTrigger: {
        start: 'top+=40% bottom',
        end: 'bottom top',
        trigger: blockRef.value,
      },
    });

    if (isDesktop() && reviewsRef.value && reviews?.length) {
      const reviewsTimeline = useGsap.timeline({
        scrollTrigger: {
          pin: true,
          end: 'bottom bottom',
          scrub: true,
          trigger: reviewsRef.value,
        },
      });

      reviewsTimeline.to(reviews, {
        yPercent: (i) => {
          if (i === 0) {
            return 0;
          }

          return -100 * i;
        },
        scale: (i) => {
          if (isLast(i, reviews.length)) {
            return 1;
          }

          const increment = 0.15 / (reviews.length - 1);

          return 0.85 + (i * increment);
        },
        delay: (i) => i * (1 / (reviews?.length ?? 1)),
        autoAlpha: 1,
        boxShadow: (i) => {
          if (i === 0) {
            return '';
          }

          return '0 -2px 5px rgba(0, 0, 0, 0.25)';
        },
      });
    }

    if (titleLeft) {
      timeline.from(titleLeft, {
        x: -100,
        autoAlpha: 0,
      });
    }

    if (titleRight) {
      timeline.from(titleRight, {
        x: 100,
        autoAlpha: 0,
      }, '-=0.25');
    }

    if (mediaItems?.length) {
      timeline.from(mediaItems, {
        stagger: 0.5,
        autoAlpha: 0,
      }, '-=0.25');
    }
  }, blockRef.value);
});

onUnmounted(() => {
  ctx?.revert();
});
</script>
